/* global CONTROLLER */

import { $body, $document } from '../utils/environment';

// naming
const NAME = 'Dependency';

export default class DependencyController {
    loadedDependencies = [];

    init (dependencies = []) {
        console.log(`%cController: ${NAME} has been loaded`, `color: ${CONTROLLER};`);

        // load all dependencies from DOM
        this.loadAll();

        // load static modules
        $.each(dependencies, (i, dependencyName) => {
            this.load(dependencyName)
                .then((dependency) => {
                    const newDependency = new dependency.default();

                    this.loadedDependencies.push(newDependency);
                    $body.data(dependencyName, newDependency);
                })
                .catch(e => console.error(e));
        });

        return this;
    }

    async load (dependencyName) {
        return import(/* webpackChunkName: "dependencies/[request]" */ `../dependencies/${dependencyName}`);
    }

    loadAll ($el = $document) {
        // get all dependencies from DOM
        const dependencies = $el.find('[data-dependency]');

        // loop through the found dependencies
        $.each(dependencies, (k, dependencyEl) => {
            const data = $(dependencyEl).data();
            const dependencyObj = {
                el: dependencyEl,
                $el: $(dependencyEl),
                data,
            };

            // trim all whitespaces and explode it on the comma
            const multipleDependencies = data.dependency.trim().split(',');

            // loop through dependencies and execute and store them
            $.each(multipleDependencies, (i, dependencyName) => {
                this.load(dependencyName)
                    .then((dependency) => {
                        const newDependency = new dependency.default(dependencyObj);

                        // store it
                        this.loadedDependencies.push(newDependency);
                        $(dependencyEl).data('_dependency', newDependency);
                    })
                    .catch(e => console.error(e));
            });
        });

        return this;
    }

    delete () {
        // Loop modules
        let i = this.loadedDependencies.length;

        // Destroy all modules
        while (i--) {
            this.loadedDependencies[i].destroy();
            this.loadedDependencies.splice(i);
        }

        return this;
    }

    destroy () {
        return this;
    }
}
