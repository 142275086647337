/* global VERSION */

import queryString from 'query-string';
import { removeLoader } from './helpers';
import { isFixed } from './is';
import { setLanguage } from '../i18n/i18n';
import { iso, headerHeight } from './environment';

const loader = () => {
    const query = queryString.parse(window.location.search);

    // only remove the loaded if there are no query strings
    if (Object.keys(query).length === 0 || (typeof query.update === 'undefined' && typeof query.translate === 'undefined'))
    {
        removeLoader();
    }
};

const adjustHeader = () => {
    const $header = $('.js-p-frontend-header');

    if ($header.length)
    {
        const $firstElement = $header.next();
        const headerIsFixed = isFixed($firstElement);

        if (headerIsFixed === true)
        {
            $firstElement.css('top', parseInt($firstElement.css('top'), 16) + headerHeight);
        }
    }
};

export default function () {
    console.log(`%cPlatform: ${VERSION} has been loaded`, 'color: yellow;');

    adjustHeader();
    setLanguage(iso);

    loader();
}
