function isElement (obj) {
    return (obj[0] || obj).nodeType;
}

function toType (obj) {
    return {}.toString.call(obj).match(/\s([a-z]+)/i)[1].toLowerCase();
}

export function typeCheckConfig (componentName, config, configTypes) {
    for (const property in configTypes)
    {
        if (Object.prototype.hasOwnProperty.call(configTypes, property))
        {
            const expectedTypes = configTypes[property];
            const value = config[property];
            const valueType = value && isElement(value) ? 'element' : toType(value);

            if (!new RegExp(expectedTypes).test(valueType))
            {
                throw new Error(
                    `${componentName.toUpperCase()}: ` +
                    `Option "${property}" provided type "${valueType}" ` +
                    `but expected type "${expectedTypes}".`);
            }
        }
    }
}

export const removeLoader = () => {
    $('.p-o-site').removeClass('p-o-is-loading');
};

export const getMousePos = (ev = window.event) => {
    let posX = 0;
    let posY = 0;

    if (ev.pageX || ev.pageY)
    {
        posX = ev.pageX;
        posY = ev.pageY;
    }
    else if (ev.clientX || ev.clientY)
    {
        posX = ev.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posY = ev.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return {
        x: posX,
        y: posY,
    };
};
