/* eslint-disable import/no-named-default, no-param-reassign */
/* global CONTROLLER */

import '../vendor/growl';
import { $document } from '../utils/environment';
import { isClass } from '../utils/is';

// naming
const NAME = 'Action';
const EVENT_KEY = `.${NAME}`;
const Event = {
    CLICK: `click${EVENT_KEY}`,
    CHANGE: `change${EVENT_KEY}`,
    UPDATE: `update${EVENT_KEY}`,
};

export default class ActionController {
    init () {
        console.log(`%cController: ${NAME} has been loaded`, `color: ${CONTROLLER};`);

        this.check();
        this.listeners();
    }

    update () {
        this.destroy();
        this.check();
        this.listeners();

        return this;
    }

    register ({ element, container }) {
        // append element to DOM
        const $container = container instanceof jQuery ? container : $(container);
        $container.append(element);

        // update the listeners
        this.update();
    }

    check () {
        // check if there are any inputs in the DOM, if so load the action
        $.each($document.find('[data-action]'), (i, e) => {
            const $this = $(e);
            const action = $this.data('action');
            const isActive = $this.is(':checked') || $this.is(':selected');

            if (isActive)
            {
                this.load(action)
                    .then(module => module.default($this))
                    .catch(error => this.error(error));
            }
        });
    }

    listeners () {
        $document.off(Event.CLICK);
        $document.off(Event.CHANGE);

        // elements
        $document.on(Event.CLICK, '[data-action]:not(input)', (e) => {
            e.preventDefault();
            this.loadAction(e);
        });

        // inputs
        $document.on(Event.CHANGE, '[data-action]', e => this.loadAction(e));
    }

    async load (action) {
        return import(/* webpackChunkName: "actions/[request]" */ `../actions/${action}`);
    }

    loadAction (event) {
        const $this = $(event.target);
        const action = $this.data('action');

        event.relatedTarget = $this;

        this.load(action)
            .then((module) => {
                if (isClass(module.default))
                {
                    const Module = new module.default(event);
                    $this.data('_action', Module);
                }
                else
                {
                    const Module = module.default(event);
                    $this.data('_action', Module);
                }
            })
            .catch(error => this.error(error, action));
    }

    error (error, action) {
        const title = error.errorCode === 3 ? 'Warning' : 'Error';
        let message = error.errorCode === 3 ? error.message : error.errorMessage;

        if (typeof message === 'undefined')
        {
            message = `Cannot find module: ${action}`;
            console.error(error);
        }

        $.growl.error({ title, message });
    }

    destroy () {
        // elements
        $document.find('[data-action]').not('input').off(Event.CLICK);

        // inputs
        $document.find('[data-action]').off(Event.CHANGE);

        return this;
    }
}
