import * as i18n from './i18n-data.json';

// default language
let currentLang = 'nl';

/**
 * Overwrite the current language
 *
 * @param iso
 */
export function setLanguage (iso) {
    currentLang = iso.toLowerCase();
}

/**
 * Get a translated string
 *
 * @param index
 * @returns {*}
 */
export function translate (index) {
    return (i18n.default[currentLang] && i18n.default[currentLang][index]) || index;
}
