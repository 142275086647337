import '@babel/polyfill';

import DependencyController from './controllers/DependencyController';
import ActionController from './controllers/ActionController';
import {
    $window,
    $body,
    $document,
    DELAY,
} from './utils/environment';

// Global functions and tools
import globals from './utils/globals';

// Actions
const actions = new ActionController();

// Dependencies
const dependencies = new DependencyController();

class Platform {
    /**
     * Init platform
     */
    init () {
        globals();

        // dependencies
        dependencies.init(['Bootstrap']);

        // actions
        actions.init();

        // flag window as loaded
        $body.addClass('p-is-loaded');

        // disabled app scroll functions
        $document.on('app.loaded.Scroll', e => setTimeout(() => e.module.destroy(true), 1000));
    }

    reinit () {
        // update dependencies
        dependencies.delete().init();

        // update actions
        actions.update();
    }
}

{
    // initially flag as not initialised
    let isInitialised = false;

    // init the app
    const init = () => {
        const platform = new Platform(); // create plugin
        platform.init(); // trigger the class to init it
    };

    // init the app after window is loaded
    $window.on('load', () => {
        if (!isInitialised)
        {
            // flag as initialised
            isInitialised = true;
            init();
        }
    });

    // if app has not been initialised after delay init it again
    setTimeout(() => {
        if (!isInitialised)
        {
            // flag as initialised
            isInitialised = true;
            init();
        }
    }, DELAY);
}
