import { $window, minWidthSM, minWidthMD, minWidthLG } from './environment';

const { toString } = Object.prototype;
const arrayLikePattern = /^\[object (?:Array|FileList)\]$/;

// thanks, http://perfectionkills.com/instanceof-considered-harmful-or-how-to-write-a-robust-isarray/
export function isArray (thing) {
    return toString.call(thing) === '[object Array]';
}

export function isArrayLike (obj) {
    return arrayLikePattern.test(toString.call(obj));
}

export function isEqual (a, b) {
    if (a === null && b === null)
    {
        return true;
    }

    if (typeof a === 'object' || typeof b === 'object')
    {
        return false;
    }

    return a === b;
}

// http://stackoverflow.com/questions/18082/validate-numbers-in-javascript-isnumeric
export function isNumeric (thing) {
    return !Number.isNaN(parseFloat(thing)) && Number.isFinite(thing);
}

export function isObject (thing) {
    return (thing && toString.call(thing) === '[object Object]');
}

export function isFunction (thing) {
    const getType = {};
    return thing && getType.toString.call(thing) === '[object Function]';
}

export function isClass (fn) {
    return (typeof fn === 'function' && /^(?:class\s+|function\s+(?:_class|_default|[A-Z]))/.test(fn)) || Object.keys(fn.prototype).length;
}

export function isjQuery (element) {
    return element instanceof jQuery;
}

export function isLocal () {
    const split = window.location.hostname.split('.');
    return (split[0] === 'localhost' || split[1] === 'localhost' || split[1] === 'local' || split[1] === 'dev' || split[1] === '127.0.0.1');
}

export function isFixed (elem) {
    if (typeof elem === 'undefined')
    {
        throw new Error('isFixed: No element defined');
    }

    const $elem = (typeof elem === 'string' && typeof elem !== 'object' ? $(elem) : elem);

    let fixed = false;

    if ($elem.css('position') === 'fixed')
    {
        fixed = true;
    }

    return fixed;
}

export const isMobile = () => {
    let mobile = false;

    if ($window.width() < (minWidthSM - 1))
    {
        mobile = true;
    }

    return mobile;
};

export const isTablet = (orientation) => {
    if (typeof orientation === 'undefined')
    {
        throw new Error('isTablet: No orientation defined');
    }

    let tablet = false;

    if (orientation === 'portrait' && $window.width() > (minWidthSM - 1) && $window.width() < minWidthMD)
    {
        tablet = true;
    }

    if (orientation === 'landscape' && $window.width() > minWidthMD && $window.width() < minWidthLG)
    {
        tablet = true;
    }

    return tablet;
};

