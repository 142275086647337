const DELAY = 3000; // delay as fallback for when app has not been initialised
const $document = $(document);
const $window = $(window);
const $html = $(document.documentElement);
const $body = $(document.body);
const $page = $('.p-o-page');
const $header = $('.p-o-header') || null;
const headerHeight = $header.outerHeight() || 0;
const $intro = $('.p-o-intro') || null;
const introHeight = $intro.outerHeight() || 0;
const minWidthSM = 768;
const minWidthMD = 992;
const minWidthLG = 1224;
const iso = $html.attr('lang').toLowerCase();

export {
    $document,
    $window,
    $html,
    $body,
    $page,
    minWidthSM,
    minWidthMD,
    minWidthLG,
    $header,
    headerHeight,
    introHeight,
    DELAY,
    iso,
};
